import { db } from "@/firebase/config"
import { addDoc, collection } from "firebase/firestore"
import { ref } from "vue"

const useCollection = (c) => {
    const error = ref(null)
    const isPending = ref(false)

    // collection reference
    let colRef = collection(db, c)

    const addDoucment = async (document) => {
        error.value = null
        isPending.value = true
        try {
            const res = await addDoc(colRef, document)
            isPending.value = false
            return res
        } catch (err) {
            console.log(err.message)
            error.value = err.message
            isPending.value = false
        }
    }

    return { error, isPending, addDoucment }
}

export default useCollection